import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Filter
} from "react-admin";

const KisiFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      // format={(v: any) => v.toUpperCase()}
      parse={(v: any) => v.toLocaleUpperCase("TR")}
      label="Isim"
      source="First_Name"
      alwaysOn
    />
    <TextInput
      // format={(v: any) => v.toUpperCase()}
      parse={(v: any) => v.toLocaleUpperCase("TR")}
      label="İkinci İsim"
      source="Middle_Name"
      alwaysOn
    />
    <TextInput
      // format={(v: any) => v.toUpperCase()}
      parse={(v: any) => v.toLocaleUpperCase("TR")}
      label="Soy İsim"
      source="Last_Name"
      alwaysOn
    />
  </Filter>
);

export const KisilerList = (props: any) => (
  <List
    sort={{ field: "First_Name", order: "ASC" }}
    filters={<KisiFilter />}
    title="Kişiler Listesi"
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="First_Name"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Last_Name"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Middle_Name"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="E_mail_Address"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="E_mail_2_Address"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="E_mail_3_Address"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Home_Phone"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Home_Phone_2"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Business_Phone"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Business_Phone_2"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="Mobile_Phone"
      />
    </Datagrid>
  </List>
);

export const KisilerEdit = (props: any) => (
  <Edit title="Kişi Düzenle" {...props}>
    <SimpleForm>
      <TextInput
        source="First_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput
        source="Last_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput
        source="Middle_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput source="Title" />
      <TextInput source="Job_Title" />
      <TextInput source="Suffix" />
      <TextInput source="E_mail_Address" />
      <TextInput source="E_mail_2_Address" />
      <TextInput source="E_mail_3_Address" />
      <TextInput source="Home_Phone" />
      <TextInput source="Home_Phone_2" />
      <TextInput source="Business_Phone" />
      <TextInput source="Business_Phone_2" />
      <TextInput source="Mobile_Phone" />
      <TextInput source="Car_Phone" />
      <TextInput source="Other_Phone" />
      <TextInput source="Pager" />
      <TextInput source="Business_Fax" />
      <TextInput source="Other_Fax" />
      <TextInput source="Company_Main_Phone" />
      <TextInput source="Company" />
      <TextInput source="Business_Street" />
      <TextInput source="Business_City" />
      <TextInput source="Business_Country_Region" />
      <TextInput source="Other_City" />
      <TextInput source="Other_Country_Region" />
      <TextInput source="Personal_Web_Page" />
      <TextInput source="Birthday" />
      <TextInput source="Notes" />
    </SimpleForm>
  </Edit>
);

export const KisilerCreate = (props: any) => (
  <Create title="Kişi Ekle" {...props}>
    <SimpleForm redirect="list">
      <TextInput
        source="First_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput
        source="Last_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput
        source="Middle_Name"
        parse={(v: any) => v.toLocaleLowerCase("TR")}
        format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
      />
      <TextInput source="Title" />
      <TextInput source="Job_Title" />
      <TextInput source="Suffix" />
      <TextInput source="E_mail_Address" />
      <TextInput source="E_mail_2_Address" />
      <TextInput source="E_mail_3_Address" />
      <TextInput source="Home_Phone" />
      <TextInput source="Home_Phone_2" />
      <TextInput source="Business_Phone" />
      <TextInput source="Business_Phone_2" />
      <TextInput source="Mobile_Phone" />
      <TextInput source="Car_Phone" />
      <TextInput source="Other_Phone" />
      <TextInput source="Pager" />
      <TextInput source="Business_Fax" />
      <TextInput source="Other_Fax" />
      <TextInput source="Company_Main_Phone" />
      <TextInput source="Company" />
      <TextInput source="Business_Street" />
      <TextInput source="Business_City" />
      <TextInput source="Business_Country_Region" />
      <TextInput source="Other_City" />
      <TextInput source="Other_Country_Region" />
      <TextInput source="Personal_Web_Page" />
      <TextInput source="Birthday" />
      <TextInput source="Notes" />
    </SimpleForm>
  </Create>
);
