import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EditButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  DateInput,
  FormTab,
  LongTextInput,
  ArrayInput,
  SimpleFormIterator,
  Filter,
  NumberInput,
  FileField,
  FileInput
} from "react-admin";

const HastaFilter = (props: any) => (
  <Filter {...props}>
    <TextInput
      label="Isim"
      source="isim"
      alwaysOn
      parse={(v: any) => v.toLocaleUpperCase("TR")}
    />
    <TextInput
      label="Soyisim"
      source="soyisim"
      alwaysOn
      parse={(v: any) => v.toLocaleUpperCase("TR")}
    />
    <TextInput
      label="Cep"
      source="telefon_cep"
      alwaysOn
      parse={(v: any) => v.toLocaleUpperCase("TR")}
    />
  </Filter>
);

export const HastaList = (props: any) => (
  <List filters={<HastaFilter />} title="Danışan Listesi" {...props}>
    <Datagrid>
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="dosya_no"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="isim"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="soyisim"
      />
      <TextField
        style={{
          textTransform: "uppercase"
        }}
        source="telefon_cep"
      />
      <EditButton />
    </Datagrid>
  </List>
);

const optionRenderer = (choice: any) => `${choice.name} ${choice.lastname}`;

export const HastaDuzenle = (props: any) => (
  <Edit title="Danışan Duzenle" {...props}>
    <TabbedForm redirect="list">
      <FormTab label="Kisisel Bilgiler">
        <TextInput source="dosya_no" />
        <TextInput
          source="isim"
          parse={(v: any) => v.toLocaleLowerCase("TR")}
          format={(v: any) => v.toLocaleUpperCase("TR")}
        />
        <TextInput
          source="soyisim"
          parse={(v: any) => v.toLocaleLowerCase("TR")}
          format={(v: any) => v.toLocaleUpperCase("TR")}
        />
        <SelectInput
          source="cinsiyet"
          choices={choices}
          optionText="full_text"
          optionValue="sex"
        />
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="dogum_tarih"
          label="Dogum Tarihi"
        />
      </FormTab>
      <FormTab label="Aile Bilgileri ve İletişim">
        <TextInput source="telefon_cep" label="Cep No" />
        <TextInput source="telefon_cep_2" label="Cep 2 No" />
        <TextInput source="telefon_ev" label="Ev No" />
        <TextInput source="email" />
        <TextInput source="email_2" label="Email 2" />
        <LongTextInput source="adres" />
        <TextInput source="anne_isim" label="Anne Isim" />
        <TextInput source="baba_isim" label="Baba Isim" />
        <ArrayInput label="Kardesler" source="kardesler">
          <SimpleFormIterator>
            <NumberInput label="Kac Yasinda" source="yas" />
            <TextInput label="Isim" source="isim" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Cocuklar" source="cocuklar">
          <SimpleFormIterator>
            <NumberInput label="Kac Yasinda" source="yas" />
            <TextInput label="Isim" source="isim" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Terapi Takip">
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="ilk_gelis_tarih"
          label=" İlk Geliş Tarihi"
        />
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="son_gelis_tarih"
          label=" Son Geliş Tarihi"
        />
        <ReferenceInput
          label="İlk Görüşmeyi Yapan Uzman"
          source="ilk_uzman"
          reference="doktorlar"
        >
          <SelectInput optionText={optionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          label="Takip Eden Uzman"
          source="simdiki_uzman"
          reference="doktorlar"
        >
          <SelectInput optionText={optionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          label="Takip Eden Uzman 2"
          source="ikinci_uzman"
          reference="doktorlar"
        >
          <SelectInput optionText={optionRenderer} />
        </ReferenceInput>
        <LongTextInput source="sebep" label="Başvuru Sebebi" />
        <LongTextInput source="problem" label="Problem Tanımı" />
        <LongTextInput source="tani" label="Tanı" />
        <ArrayInput label="Terapi Tarihcesi" source="terapiler">
          <SimpleFormIterator>
            <DateInput
              options={{ format: "DD/MM/YYYY" }}
              source="tarih"
              label="Tarih"
            />
            <ReferenceInput
              label="Doktor"
              source="doktor_id"
              reference="doktorlar"
            >
              <SelectInput optionText={optionRenderer} />
            </ReferenceInput>

            <LongTextInput source="notlar" label="Notlar" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Yapilan Testler">
        <ArrayInput labe="Dokümanlar" source="dokumanlar">
          <SimpleFormIterator>
            <FileInput
              source="file"
              label="Dokümanlar"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="testler">
          <SimpleFormIterator>
            <DateInput
              options={{ format: "DD/MM/YYYY" }}
              source="tarih"
              label="Tarih"
            />
            <ReferenceInput
              label="Yapilan Test"
              source="test_id"
              reference="testler"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <LongTextInput source="notlar" label="Notlar" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const choices = [
  { _id: 1, full_text: "KADIN", sex: "K" },
  { _id: 2, full_text: "ERKEK", sex: "E" }
];

export const HastaCreate = (props: any) => (
  <Create {...props} title="Hasta Olustur">
    <TabbedForm redirect="list">
      <FormTab label="Kisisel Bilgiler">
        <TextInput source="dosya_no" />
        <TextInput
          source="isim"
          label="Isim"
          parse={(v: any) => v.toLocaleLowerCase("TR")}
          format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
        />
        <TextInput
          source="soyisim"
          label="Soyisim"
          parse={(v: any) => v.toLocaleLowerCase("TR")}
          format={(v: any) =>  v ? v.toLocaleUpperCase("TR") : '' }
        />
        <SelectInput
          source="cinsiyet"
          choices={choices}
          optionText="full_text"
          optionValue="sex"
        />
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="dogum_tarih"
          label="Dogum Tarihi"
        />
      </FormTab>
      <FormTab label="Aile Bilgileri ve İletişim">
        <TextInput source="telefon_cep" label="Cep No" />
        <TextInput source="telefon_ev" label="Ev No" />
        <TextInput source="email" />
        <LongTextInput source="adres" />
        <TextInput source="anne_isim" label="Anne Isim" />
        <TextInput source="baba_isim" label="Baba Isim" />
        <ArrayInput label="Kardesler" source="kardesler">
          <SimpleFormIterator>
            <NumberInput label="Kac Yasinda" source="yas" />
            <TextInput label="Isim" source="isim" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput label="Cocuklar" source="cocuklar">
          <SimpleFormIterator>
            <NumberInput label="Kac Yasinda" source="yas" />
            <TextInput label="Isim" source="isim" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Terapi Takip">
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="ilk_gelis_tarih"
          label=" İlk Geliş Tarihi"
        />
        <DateInput
          options={{ format: "DD/MM/YYYY" }}
          source="son_gelis_tarih"
          label=" Son Geliş Tarihi"
        />
        <ReferenceInput
          label="İlk Görüşmeyi Yapan Uzman"
          source="ilk_uzman"
          reference="doktorlar"
        >
          <SelectInput optionText={optionRenderer} />
        </ReferenceInput>
        <ReferenceInput
          label="Takip Eden Uzman"
          source="simdiki_uzman"
          reference="doktorlar"
        >
          <SelectInput optionText={optionRenderer} />
        </ReferenceInput>
        <LongTextInput source="sebep" label="Başvuru Sebebi" />
        <LongTextInput source="problem" label="Problem Tanımı" />
        <LongTextInput source="tani" label="Tanı" />
        <ArrayInput label="Terapi Tarihcesi" source="terapiler">
          <SimpleFormIterator>
            <DateInput
              options={{ format: "DD/MM/YYYY" }}
              source="tarih"
              label="Tarih"
            />
            <ReferenceInput
              label="Doktor"
              source="doktor_id"
              reference="doktorlar"
            >
              <SelectInput optionText={optionRenderer} />
            </ReferenceInput>

            <LongTextInput source="notlar" label="Notlar" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Yapilan Testler">
        <ArrayInput labe="Dokümanlar" source="dokumanlar">
          <SimpleFormIterator>
            <FileInput
              source="file"
              label="Dokümanlar"
              accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayInput source="testler">
          <SimpleFormIterator>
            <DateInput
              options={{ format: "DD/MM/YYYY" }}
              source="tarih"
              label="Tarih"
            />
            <ReferenceInput
              label="Yapilan Test"
              source="test_id"
              reference="testler"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>

            <LongTextInput source="notlar" label="Notlar" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
