import React from "react";
import "./App.css";
import { Admin, Resource, Login, ShowGuesser } from "react-admin";
import { HastaCreate, HastaList, HastaDuzenle } from "./Hasta";
import { DoktorList, DoktorCreate, DoktorEdit } from "./Doktor";
import { TestCreate, TestEdit, TestList } from "./Testler";
import { KisilerList, KisilerEdit, KisilerCreate } from "./Kisiler";
import turkishMessages from "ra-language-turkish";
import { ParseAuth } from "ra-data-parse";
import ParseClient from "./client/parseClient";

const messages = {
  tr: turkishMessages
};
const i18nProvider = () => messages.tr;

// const dataProvider = FirebaseDataProvider(config, options);
// const authProvider = FirebaseAuthProvider(config, options);
// const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const MyLoginPage = () => (
  <Login backgroundImage="https://images.unsplash.com/photo-1464037880320-be5f34df1397?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80" />
);

const parseConfig = {
  URL: "https://pg-app-fsmyestv40pxrtbe3qzuhd190d5ue0.scalabl.cloud/1/",
  JAVASCRIPT_KEY: "Ds3yls4HrMDEhVCeonV21jThMOOY9KOLoLXQTm4k",
  APP_ID: "R0q0BvXJ0a8A2oA5m3WNKBGONOmfLhKL5QOroYB9"
};

const dataProvider = ParseClient(parseConfig);
const authProvider = ParseAuth(parseConfig);

const App: React.FC = () => {
  return (
    <Admin
      locale="tr"
      i18nProvider={i18nProvider}
      loginPage={MyLoginPage}
      // customSagas={[firebaseRealtime]}
      dataProvider={dataProvider}
      authProvider={authProvider}
    >
      <Resource
        options={{
          label: "Danışanlar"
        }}
        name="danisanlar"
        list={HastaList}
        create={HastaCreate}
        edit={HastaDuzenle}
      />
      <Resource
        options={{
          label: "Kişiler"
        }}
        name="contacts"
        list={KisilerList}
        show={ShowGuesser}
        edit={KisilerEdit}
        create={KisilerCreate}
      />
      <Resource
        options={{
          label: "Testler"
        }}
        name="testler"
        list={TestList}
        create={TestCreate}
        edit={TestEdit}
      />
      <Resource
        options={{
          label: "Uzmanlar"
        }}
        name="doktorlar"
        list={DoktorList}
        create={DoktorCreate}
        edit={DoktorEdit}
      />
    </Admin>
  );
};

export default App;
